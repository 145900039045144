import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './pvp.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkePVPIntro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to PvP Arena</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_pvp.jpg"
            alt="PVP"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to PvP Arena</h1>
          <h2>Everything you need to know about PVP in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content with-italics">
        <SectionHeader title="Foreword" />
        <p>
          This guide has been created by <strong>awesome21snake</strong>. If you
          have any feedback about the guide, catch him on our Discord!
        </p>
        <SectionHeader title="Introduction" />
        <p>
          Greetings, Commanders! Today I’ll be taking you through each and
          everything I know about PvP in Nikke: Goddess of Victory. I have spent
          hours discussing PvP with people whom I believe are the best of the
          best when it comes to this game mode, and with their and my own
          thoughts that I have developed along the way, I hope that this guide
          will be enough for everyone to not worry or think a lot about PvP
          anymore, or if they are willing to learn, they get something out of
          this. This guide consists of material from different sources which
          will be appropriately linked as a source.
        </p>
        <p>The guide will be split into 5 parts:</p>
        <ul>
          <li>
            <strong>Introduction to PvP</strong> (this guide)
          </li>
          <li>
            <strong>PvP Mechanics</strong>
          </li>
          <li>
            <strong>Burst Energy Generation Guide</strong>
          </li>
          <li>
            <strong>Basic Team-building and Meta Nikkes</strong>
          </li>
          <li>
            <strong>Meta Teams and Archetypes</strong>
          </li>
        </ul>
        <p>
          This article entails the first of the 5, where we will introduce PvP
          Arena to new players! And here you can find the other guides in this
          series:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="PvP Arena Mechanics"
            link="/nikke/guides/pvp-mechanics"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp2.jpg"
                alt="PvP Arena Mechanics"
              />
            }
          />
          <CategoryCard
            title="PvP Burst Generation"
            link="/nikke/guides/pvp-burst"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp3.jpg"
                alt="PvP Burst Generation"
              />
            }
          />
          <CategoryCard
            title="Basic Team-building & Meta"
            link="/nikke/guides/pvp-sp-arena"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp4.jpg"
                alt="Basic Team-building & Meta"
              />
            }
          />
          <CategoryCard
            title="Meta Teams and Archetypes"
            link="/nikke/guides/pvp-sp-arena-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp5.jpg"
                alt="Meta Teams and Archetypes"
              />
            }
          />
        </Row>
        <SectionHeader title="Introduction to PvP Arena" />
        <p>
          The PvP gamemode can be accessed through the Ark tab on the Main
          Screen, present on the right side as “Arena”. The PvP menu has three
          categories:
        </p>
        <ul>
          <li>
            <strong>Rookie Arena</strong>
          </li>
          <ul>
            <li>
              The Rookie Arena is unlocked after beating Normal Campaign 3-16.
            </li>
          </ul>
          <li>
            <strong>Special Arena</strong> (commonly referred to as SP Arena or
            SPvP)
          </li>
          <ul>
            <li>
              The Special Arena is unlocked after beating Normal Campaign 11-13.
            </li>
          </ul>
          <li>
            <strong>Champions Arena</strong>
          </li>
          <ul>
            <li>The Champions Arena is unavailable yet.</li>
          </ul>
        </ul>
        <p>
          PvP is a fully automatic gamemode where you go up against other Nikke
          players. Each player is required to set up Defence (‘DEF’) teams which
          other players will try to counter and win against using their Attack
          (‘ATK’) teams.
        </p>
        <p>
          Now let’s talk about each PvP arena before going into detail about PvP
          mechanics.
        </p>
        <SectionHeader title="Where to find the Arena?" />
        <StaticImage src="../../../images/nikke/pvp/pvp_1.webp" alt="PVP" />
        <p>You can find the Arena by visiting the Ark in the Lobby.</p>
        <SectionHeader title="Rookie Arena" />
        <p>
          In Rookie Arena, you are fitted into a server of 100 players, which
          reshuffle and readjust according to account creation date every 3
          months. Inactive players are removed but can be re-added if they
          become active again. <span>A PvP season has a span of 14 days</span>,
          in which players go against each other for 13 days and the 14th day is
          for the server to tally and give out rewards. Players are unable to
          attack on the 14th day.
        </p>
        <p>
          <em>
            Note: It can take a few hours for players to start appearing on
            Rookie Arena Rankings at the start of the season.
          </em>
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_2.webp" alt="PVP" />
        <p>
          In Rookie Arena, <strong>players are </strong>
          <span>
            <strong>required</strong>
          </span>
          <strong> to setup one DEF team</strong>, which other players in their
          server will attack. They also have{' '}
          <strong>5 ATK team presets </strong>available, where they can create
          and save 5 teams in case they want to quickly change to a different
          attacking team against an opponent.{' '}
          <span>You only use 1 of these 5 teams to attack.</span>
        </p>
        <p>
          After every <span>win in attack and every successful defence,</span>{' '}
          players will be rewarded with{' '}
          <strong>&lsquo;Arena Points&rsquo; (Gold stars)</strong>. The amount
          of Arena Points a player receives is higher the higher your opponent
          is ranked, vice versa for defence. Adversely,{' '}
          <span>
            every loss in attack and every time you fail to defend an attack
          </span>
          , players will <strong>lose</strong> Arena Points. The amount of Arena
          Points a player receives is reduced the lower your opponent's rank was
          in attack, vice versa for defence. Players are required to attack at
          least once per season to mark them <strong>active</strong> in the new
          season. In addition to this, every win in attack will also reward
          players with <strong>&lsquo;Arena Exchange Vouchers&rsquo;</strong>,
          also known as <strong>Arena Tickets</strong>, which can be used in the
          Arena Shop (popularly used to buy elemental skill manuals).
        </p>
        <p>
          The Rookie Arena Point system behaves like a regular Elo Rating
          System, where your elo is your Arena Points.{' '}
          <strong>Arena Points (Gold stars) will decide your ranking</strong>,
          based on which you will get <span>&lsquo;Daily&rsquo;</span> and{' '}
          <span>&lsquo;Seasonal&rsquo;</span> rewards, which are claimed through
          in-game mail on the main screen. The rewards can be seen in tables
          further down the guide.
        </p>
        <p>
          <em>
            Note: Arena Points are set back after every new season by a certain
            amount according to the player&rsquo;s final points.
          </em>
        </p>
        <p>
          Players can hit up to{' '}
          <span>
            <strong>3-5 times a day</strong>
          </span>{' '}
          in rookie arena, depending on their{' '}
          <span>infrastructure core level</span>. Players initially have 3
          tries. After infrastructure core is upgraded to level 6, they will
          have 4 tries. After infrastructure core is upgraded to level 11, they
          will have 5 tries. Additionally, they can purchase Arena chips for 100
          gems each for additional tries if they wish to do so.&nbsp;
        </p>
        <p>
          Players can see <span>3 opponents</span> at one time on their screen.
          They can choose to hit the refresh opponent button however many times
          they want to, in order to change their opponents to someone else in
          their server.{' '}
          <span>
            Players can target the same opponent multiple times in one day as
            well
          </span>
          .
        </p>
        <p>
          In the bottom right of their screens, players will find the following
          two buttons: (shown on right)
        </p>
        <ul>
          <li>Reward Info</li>
          <ul>
            <li>Here players can check the rewards for Rookie Arena ingame</li>
          </ul>
          <li>Rankings</li>
          <ul>
            <li>
              This page (shown below) shows rankings of commanders in your
              server. The more Arena Points you have, the higher your ranking.
            </li>
          </ul>
        </ul>
        <StaticImage src="../../../images/nikke/pvp/pvp_3.webp" alt="PVP" />
        <br />
        <StaticImage src="../../../images/nikke/pvp/pvp_4.webp" alt="PVP" />
        <SectionHeader title="Special Arena (SP Arena or SPvP)" />
        <p>
          In Special Arena, you are fitted into a server of minimum 200 ranked
          players, the total number of which can increase to a maximum of 5000,
          which reshuffle and readjust according to account creation date every
          3 months. Inactive players are removed but can be re-added if they
          become active again.
        </p>
        <p>
          <span>An SP Arena Season also lasts 14 days</span>, with 13 days of
          where players can go against each other, and the 14th day is for the
          server to tally and give out rewards promptly at the start of the next
          season.
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_5.webp" alt="PVP" />
        <p>
          In SP Arena, <strong>players are </strong>
          <span>
            <strong>required</strong>
          </span>
          <strong> to set up 3 DEF teams</strong>, which other players in your
          server can choose to attack. They also have to set up at least{' '}
          <strong>3 ATK </strong>teams if they want to attack, all of which are
          used to attack the 3 DEF teams of your opponents. Hence, there are no
          presets like there were in Rookie Arena, and you will have to manually
          change your team to go against another opponent, in most cases.
        </p>
        <p>
          <em>
            Players are given the option to copy all 3 ATK teams directly to DEF
            teams, or vice versa depending on whether you are on the ATK page or
            DEF page. If on the ATK page, you can copy DEF teams, and if on DEF
            page, you can copy ATK teams. The link button to the right of Round
            3 allows you to do this.
          </em>
        </p>
        <ul>
          <li>
            <strong>Your ATK Team 1 will face opponent DEF Team 1</strong>
          </li>
          <li>
            <strong>Your ATK Team 2 will face opponent DEF Team 2</strong>
          </li>
          <li>
            <strong>Your ATK Team 3 will face opponent DEF Team 3</strong>
          </li>
        </ul>
        <p>
          <strong>
            Players have to win 2 out of 3 rounds to get a win in SP Arena.
          </strong>
        </p>
        <p>
          <em>
            The CP shown in Battle Rankings is the collective CP of 3 DEF teams.
            Collective CP of your own ATK/DEF teams can be seen below your
            battle ranking on SP Arena Page.
          </em>
        </p>
        <p>
          At the start of a new season, players are initially given positions
          near to what they were ranked last season, usually according to Battle
          Rank. After every <strong>win </strong>in attack,{' '}
          <span>players will swap positions with their opponent</span>. If the{' '}
          <strong>attack fails</strong>,{' '}
          <span>both players remain in the position they were in</span> before.
        </p>
        <p>
          Similarly, after every <strong>successful defence</strong>, both{' '}
          <span>players remain in their position</span>. After every{' '}
          <strong>lost defence</strong>,{' '}
          <span>players will swap positions</span> with who they lost to.
          However, these positions are <strong>NOT</strong> your final rankings
          that awards are based on. These are to decide your{' '}
          <strong>Battle Rankings</strong>.
        </p>
        <p>
          <em>
            In addition to this, every win in attack will also reward players
            with{' '}
          </em>
          <strong>
            <em>&lsquo;Arena Exchange Vouchers&rsquo;</em>
          </strong>
          <em>, commonly known as </em>
          <strong>
            <em>Arena Tickets</em>
          </strong>
          <em>
            , which can be used in the Arena Shop (popularly used to buy
            elemental skill manuals).
          </em>
        </p>
        <p>
          Players are awarded SP Arena Points (Red and Black Coins) according to
          their Battle Ranking at an hourly rate which is updated every second.{' '}
          <strong>Muscat Rewards</strong>, which is the huge black screen in the
          middle of the <span>main screen of SP Arena</span>, slowly increases
          in percentage as the black screen turns blue over time.
        </p>
        <p>
          <span>
            Players are requested to collect these rewards at least once per
            season to mark them as{' '}
          </span>
          <span>
            <strong>active</strong>
          </span>
          <span> in the new season</span>, and so that when the SP arena season
          ends, the SP Arena Points are automatically collected. Players are
          also rewarded Arena Tickets through these Muscat rewards, which have a
          maximum collection rate. Arena Tickets collection is what the
          percentage shown by the Muscat screen actually represents. Players
          should ideally collect these rewards daily, because when they collect
          these rewards, they are also shown how much time they spent in a
          particular Battle Rank before losing to or winning against an
          opponent, and hence can check the rate of how many Arena Points they
          received for a particular time without having to calculate themselves.
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_6.webp" alt="PVP" />
        <p>
          <strong>Claiming Muscat Rewards in SP Arena shows this page</strong>
        </p>
        <p>
          You can check here what the rates of Arena Points and Tickets per hour
          will be according to your battle rank:{' '}
          <a href="#reward-tables">
            <em>SP Arena Hourly Rewards</em>
          </a>
        </p>
        <p>
          These <strong>SP Arena Points will decide your ranking</strong>, based
          on which you will get <span>&lsquo;Seasonal&rsquo;</span> gem rewards,
          which are claimed through in-game mail on the main screen. The rewards
          can be seen in this table:{' '}
          <a href="#reward-tables">
            <em>link to seasonal table.</em>
          </a>
        </p>
        <p>
          <em>
            Note: SP Arena Points are reset to 0 at the beginning of every
            season.
          </em>
        </p>
        <p>
          <em>
            Note2: As long as players do not collect SP Arena Points, their
            position in Rankings will not update.
          </em>
        </p>
        <p>
          Players can hit up to{' '}
          <span>
            <strong>2 times a day</strong>
          </span>{' '}
          in SPvP. Additionally, they can purchase SP Arena chips for 100 gems
          each for additional tries if they wish to do so.&nbsp;
        </p>
        <p>
          Players can see a maximum of 3 opponents at one time on their screen,
          each from a different rank. The first opponent is 3 battle ranks above
          you, the second opponent is 2 ranks above you, and the third opponent
          is 1 rank above you. Players can also refresh opponents an infinite
          amount of times. However, if an opponent from the{' '}
          <span>challenger rank</span> is present,{' '}
          <span>they cannot be changed</span> through refreshing. However, lower
          ranks, namely Diamond 1-10, Gold 1-3, Silver 1-3, and Bronze 1-2, have
          multiple players present in each tier (for example Diamond 1 has 5
          players). Using the &lsquo;Refresh List&rsquo; option on such ranks,
          will change opponents.
        </p>
        <p>
          <em>
            Note: If the player is either challenger 3, challenger 2 or
            challenger 1, two, one or no players at all will appear respectively
            according to their position.
          </em>
        </p>
        <p id="reward-tables">
          That is basically everything that comes to my mind when it comes to
          introducing both Rookie and Special Arena. Any small additional
          information can be found{' '}
          <span>on the top left of the screen with a small</span>
          <span>
            <strong> &lsquo;i&rsquo;</strong>
          </span>
          <span>,</span> which describes the basics of each arena in small
          detail.
        </p>
        <SectionHeader title="Reward Tables" />
        <h5>Rookie Arena</h5>
        <p>Daily Rewards are sent in mail daily</p>
        <StaticImage src="../../../images/nikke/pvp/pvp_7.webp" alt="PVP" />
        <br />
        <StaticImage src="../../../images/nikke/pvp/pvp_8.webp" alt="PVP" />
        <h5>Special Arena</h5>
        <p>
          <strong>Season End Rewards</strong>
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_9.webp" alt="PVP" />
        <h5>SP Arena Hourly Rewards</h5>
        <p>
          <strong>Challenger Tiers</strong>
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_10.webp" alt="PVP" />
        <p>
          <strong>Diamond Tiers</strong>
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_11.webp" alt="PVP" />
        <p>
          <strong>Platinum, Gold, Silver, Bronze, and Beginner Tiers</strong>
        </p>
        <StaticImage src="../../../images/nikke/pvp/pvp_12.webp" alt="PVP" />
        <SectionHeader title="Next step" />
        <p>Next we will move on to important mechanics in PVP:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="PvP Arena Mechanics"
            link="/nikke/guides/pvp-mechanics"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp2.jpg"
                alt="PvP Arena Mechanics"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkePVPIntro;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to PvP Arena | NIKKE | Prydwen Institute"
    description="Everything you need to know about PVP in NIKKE."
    game="nikke"
  />
);
